.app {
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  height: 100%;
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1000px;
  min-height: 25px;
}


.test-dropdown {
  position: relative;
  display: inline-block;
}

.test-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}