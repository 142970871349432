/* MCTestPage.css */

body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .question-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 60%;
    max-width: 700px;
    margin: auto;
  }
  
  .question {
    font-size: 20px;
    margin-bottom: 15px;
    color: #2a2a2a;
  }
  
  .answer {
    border: 1px solid #ddd;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .answer:hover {
    background-color: #f0f0f0;
  }
  
  .selected {
    background-color: #e0e0e0;
  }
  
  .answer-description, .choice-description {
    margin-top: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 5px;
  }
  
  .controls {
    margin-top: 20px;
    text-align: center;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #45a049;
  }

  .incorrect {
    color: #ff0000;
  }

  .correct {
    color: #00cc00;
  }