/* Add to your CSS file */

h3 {
    color: #333;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }

  .page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
    max-height: 90%;
  }

  .test-preview {
    width: 90%;
    overflow: scroll;
    margin: 10px;
    margin-bottom: 25px;
  }

  .test-select-options-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: end;
    height: 100%;
  }

  .test-select-options-container button {
    margin: 50px;
  }