/* Add to your CSS file */

textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }

  .mc-menu-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .mc-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mc-menu form {
    width: 100%;
  }

  .mc-menu hr {
    width: 100%;
  }

  .button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .mc-menu button {
    margin: 5px 25px;
  }

  .test-option {
    margin: 20px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-message {
    color: white;
    font-size: 24px;
    padding: 20px;
    background-color: #333;
    border-radius: 5px;
}
